<template>
    <Carousel style="max-width: 100vw" v-bind="settings" :breakpoints="breakpoints" ref="carousel" v-model="currentSlide">

        <Slide v-for="(reservation) in data2Sorted" :key="reservation?.id">

            <new-reservation-card :key="reservation?.id" :reservation="reservation" :disabled="loading1"
                @cancel="onCancelReservation1" @finish="onFinishReservation1" />
        </Slide>

    </Carousel>

    <div>
        <button @click="prev" class="carousel__prev">

            <ArrowLeftOutlined style="fontSize:80%" />

            <!-- <img src="/images/icons/arrow-left.svg" /> -->
        </button>
        <button class="carousel__next" @click="next">
            <!-- <img src="/images/icons/arrow-right.svg" /> -->
            <ArrowRightOutlined style="fontSize:80%" />

        </button>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import NewReservationCard from "@/components/admin/reservation/NewReservationCard";

import {
    ArrowRightOutlined,
    ArrowLeftOutlined,
} from '@ant-design/icons-vue';


import 'vue3-carousel/dist/carousel.css'
import moment from "moment";

export default defineComponent({
    name: 'Slider',
    props: {
        data2: Array,
        onCancelReservation1: Function,
        onFinishReservation1: Function,
        loading1: Boolean,
    },
    components: {
        Carousel,
        Slide,
        NewReservationCard,
        ArrowRightOutlined,
        ArrowLeftOutlined,
    },
  computed: {
      data2Sorted() {
        const res = [...this.data2];

        res.sort((a, b) => moment(a?.timeFrom, "YYYY-MM-DDTHH:mm") - moment(b?.timeFrom, "YYYY-MM-DDTHH:mm"));

        return res;
      }
  },

    data: (props) => ({


        settings: {
            itemsToShow: 1,
            snapAlign: 'start',
            wrapAround: true,
        },

        breakpoints: {
            700: {
                itemsToShow: props.data2.length > 2 ? 2 : props.data2.length,
                snapAlign: 'start',
            },
            900: {
                itemsToShow: props.data2.length > 3 ? 3 : props.data2.length,
                snapAlign: 'start',
            },

            1180: {
                itemsToShow: props.data2.length > 4 ? 4 : props.data2.length,
                snapAlign: 'start',
            },

            1366: {
                itemsToShow: props.data2.length > 4 ? 4 : props.data2.length,
                snapAlign: 'start',
            },

            9999: {
                itemsToShow: props.data2.length > 4 ? 4 : props.data2.length,
                snapAlign: 'start',
            },
        },
    }),
    methods: {
        next() {
            this.$refs.carousel.next()
        },
        prev() {
            this.$refs.carousel.prev()
        },
    },

})
</script>
  
<style >
.carousel__prev,
.carousel__next {
    /* top: -10% !important; */
    top: -10% !important;
}

.carousel__prev {
    left: 90% !important;
    /* left: 140% !important; */
}

.carousel__next {
    left: 95% !important;
    /* left: 145% !important; */
}

.carousel__prev,
.carousel__next {
    max-height: 32px;
    max-width: 32px;
    box-sizing: content-box;

    text-align: center;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    border: 0;
    cursor: pointer;
    margin: 0 10px;
    transform: translateY(-50%);
}
</style>