<template>
    <section v-if="loading" class="ant-spin-nested-loading">
        <div class="spin-container" v-if="loading">
            <a-spin />
        </div>
    </section>
    <section v-else>
        <section class="favorites">
            <a-page-header style="margin-bottom: 12px;" title="Избранные" />
            <div style="display: flex; justify-content: space-between; width: calc(100%);">
                <favorite-card isFavorite to="workplaces" img="/images/icons/work.svg" type="Рабочие места"
                    :fuvObj="workPlace" />
                <favorite-card isFavorite to="meetingRooms" img="/images/icons/record.svg" type="Переговорные"
                    :fuvObj="meetRoom" />
                <favorite-card isFavorite to="parkingLots" img="/images/icons/shuttle.svg" type="Парковки"
                    :fuvObj="parking" />
                <favorite-card isFavorite to="lockers" img="/images/icons/lock.svg" type="Локеры" :fuvObj="loker" />
            </div>
        </section>

        <section class="myReservations">
            <a-page-header style="margin-bottom: 0;" title="Мои бронирования">
            </a-page-header>

            <main style="width: 100%;">
                <div style="padding-left: 10px; width: 100%;">
                    <main v-if="data?.length > 0 || loading" class="ant-spin-nested-loading">
                        <div class="spin-container" v-if="loading">
                            <a-spin />
                        </div>
                        <section style="width: 100%;" :class="{ 'ant-spin-blur': loading }">
                            <Slider :data2=data :loading1=loading :onCancelReservation1=onCancelReservation
                                :onFinishReservation1=onFinishReservation />
                        </section>
                    </main>
                    <main v-else>
                        <a-empty>
                            <template #description>
                                Здесь будут отображаться ваши бронирования. <br />
                                Вы можете создавать, изменять и отменять их. <br /><br />
                                Создайте своё <router-link :to="{ name: 'mapView', params: { id: 'root' } }">первое
                                    бронирование!</router-link>
                            </template>
                        </a-empty>
                    </main>
                </div>
            </main>
        </section>
        <section class="toReserve">
            <a-page-header style="margin-bottom: 12px; padding-top: 0;" title="Забронировать" />
            <div style="display: flex; justify-content: space-between; width: calc(100%);">
                <favorite-card to="findSlots" img="/images/icons/work.svg" type="Рабочие места" :fuvObj="freeWorkPlace" />
                <favorite-card to="findSlots" img="/images/icons/record.svg" type="Переговорные" :fuvObj="freeMeetRoom" />
                <favorite-card to="findSlots" img="/images/icons/shuttle.svg" type="Парковки" :fuvObj="freeParking" />
                <favorite-card to="findSlots" img="/images/icons/lock.svg" type="Локеры" :fuvObj="freeLoker" />
            </div>
        </section>
    </section>
</template>

<script>
import FavoriteCard from "@/components/common/FavoriteCard";
import Slider from "@/components/common/MainPageComponetns/Slider.vue";
import { onMounted } from "@vue/runtime-core";
import { getListData as getReservationsData, requestAPI as reservationAPI } from "@/compositions/reservations";
import { notification } from "ant-design-vue";
import { ref } from "@vue/reactivity";

export default {
    name: "MainNavPage",

    setup() {
        let workPlace = ref();
        let loker = ref();
        let parking = ref();
        let meetRoom = ref();


        let freeWorkPlace = ref();
        let freeLoker = ref();
        let freeParking = ref();
        let freeMeetRoom = ref();


        const {
            loading, data,
            initFilterDefaults, findReservation,
            clientMode, searchMode,
        } = getReservationsData();
        const { removeReservation, finishReservation, fetchFavoriteObjectsByType,
            fetchFreeObjectsByType
        } = reservationAPI();

        onMounted(async () => {
            searchMode.value = 'reservations';
            clientMode.value = 'user';
            await initFilterDefaults();
            await findReservation();
            const fuvObj = await fetchFavoriteObjectsByType();
            const freeObj = await fetchFreeObjectsByType();

            workPlace.value = fuvObj.data.find(el => el.name === 'Рабочее место')
            loker.value = fuvObj.data.find(el => el.name === 'Локер')
            parking.value = fuvObj.data.find(el => el.name === 'Парковка')
            meetRoom.value = fuvObj.data.find(el => el.name === 'Переговорная')

            freeWorkPlace.value = freeObj.data.find(el => el.name === 'Рабочее место')
            freeLoker.value = freeObj.data.find(el => el.name === 'Локер')
            freeParking.value = freeObj.data.find(el => el.name === 'Парковка')
            freeMeetRoom.value = freeObj.data.find(el => el.name === 'Переговорная')

            console.log(
                freeMeetRoom.value = freeObj.data.find(el => el.name === 'Переговорная')
            );

            console.log(
                freeMeetRoom.value

            );

        });

        async function onCancelReservation(reservationId) {
            await removeReservation(reservationId);
            await findReservation();
            notification.success({
                message: 'Успех',
                description: 'Бронирование удалено',
            });
        }

        async function onFinishReservation(reservationId) {
            await finishReservation(reservationId);
            await findReservation();
            notification.success({
                message: 'Успех',
                description: 'Бронирование завершено',
            });
        }

        return {
            loading,
            data,
            onCancelReservation, onFinishReservation,
            workPlace,
            loker,
            parking,
            meetRoom,
            freeWorkPlace,
            freeLoker,
            freeParking,
            freeMeetRoom
        };
    },
    components: {
        FavoriteCard,
        Slider,

    }
}
</script>

<style lang="less" scoped>
.favorites {
    margin-bottom: 24px;
}

.myReservations {
    margin-bottom: 0px;
    width: 100%;

  .anticon svg {
    color: #21242B !important;
  }
}

.ant-spin-container {
  display: flex;
  width: 100vw;
  height: 50vh;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
}
</style>

<style lang="less">
.myReservations {
  @media screen and (max-width: 1024px) {
    .carousel__prev:hover, .carousel__next:hover {
      --vc-nav-color-hover: #21242B;
    }
  }
}
</style>